import { Injectable } from '@angular/core';

@Injectable()
export class FeatureMapService {
  // TODO: Map paths & features along to enable Roles to be mapped across easily to manage ACL
  constructor() {}

  featureModules: { name: string; label: string }[] = [
    { name: 'patients', label: 'Patients Module' },
    { name: 'history', label: 'History Module' },
    { name: 'officeForms', label: 'Office Forms Module' },
    { name: 'intake', label: 'Patient Intake Module' },
    { name: 'orders', label: 'Orders & Referrals Module' },
    { name: 'pe', label: 'Physical Exams Module' },
    { name: 'referral', label: 'Attorney Referral Module' },
    { name: 'scheduler', label: 'Scheduler & Calendar Module' },
    { name: 'workability', label: 'Workability Module' },
    { name: 'documents', label: 'Patient Documents Module' },
    // { name: 'assessment', label: 'Plan & Assessment Module' },
    { name: 'soap', label: 'Office Visits & SOAP Module' },
    { name: 'billing', label: 'Billing Module' },
    { name: 'claims', label: 'Claims Module' },
    { name: 'docutils', label: 'Document Utilities Module' },
    { name: 'share', label: 'Record Share Module' },
    { name: 'reports', label: 'Reports Module' },
    { name: 'accrec', label: 'Accounts Receivables Module' },
    { name: 'superbill', label: 'Superbill Module' },
    { name: 'proofOfService', label: 'Proof of Service Module' },
    { name: 'caseFlow', label: 'CaseFLO Module' },
    { name: 'esign', label: 'eSign Module' },
  ];

  getFeatureModules() {
    return this.featureModules;
  }
}

export class FeatureName {
  static ASSESSMENT = 'assessment';
  static DOCUMENTS = 'documents';
  static DOCUTILS = 'docutils';
  static HISTORY = 'history';
  static ROR = 'ror';
  static OFFICE_FORMS = 'officeForms';
  static INTAKE = 'intake';
  static ORDERS = 'orders';
  static PATIENTS = 'patients';
  static PE = 'pe';
  static REPORTS = 'reports';
  static SCHEDULER = 'scheduler';
  static SHARE = 'share';
  static SOAP = 'soap';
  static BILLING = 'billing';
  static CLAIMS = 'claims';
  static ACCREC = 'accrec';
  static WORKABILITY = 'workability';
  static REFERRAL = 'referral';
  static SUPERBILL = 'superbill';
  static ESIGN = 'esign';
  static POS = 'proofOfService';
  static CASE_FLOW = 'caseFlow';
}

export interface FeatureModules {
  assessment?: boolean;
  documents?: boolean;
  history?: boolean;
  officeForms?: boolean;
  intake?: boolean;
  orders?: boolean;
  patients?: boolean;
  pe?: boolean;
  reports?: boolean;
  scheduler?: boolean;
  share?: boolean;
  billing?: boolean;
  claims?: boolean;
  accrec?: boolean;
  workability?: boolean;
  referral?: boolean;
  soap?: boolean;
  superbill?: boolean;
  esign?: boolean;
  proofOfService?: boolean;
  caseFlow?: boolean;
}

// TODO: Edit Role-Auth Service levaraging FeaturePath, instead of hardcoded strings
export class FeaturePath {
  // under path /dashboard
  public static CALENDAR = 'calendar';
  public static PATIENTS = 'patients';
  public static CALLBACK = 'callback';
  public static REFERRALS = 'referrals';
  public static DOCUMENTS = 'documents'; // related to DOCUTILS module
  public static HISTORY = 'history-list';
  public static OFFICE_FORMS = 'office-forms';
  public static ESIGN = 'esign';
  public static ROR = 'record-review';

  // under path /patients
  public static PATIENT_NEW = 'new'; // patient-new
  public static PATIENT_ACCOUNT = 'account';
  public static PATIENT_APPOINTMENTS = 'appointments';
  public static PATIENT_APPOINTMENT_NEW = 'app-new';
  public static PATIENT_INTAKE = 'forms';
  public static PATIENT_HISTORY = 'history';
  public static PATIENT_PE = 'pe';
  public static PATIENT_ORDER = 'order';
  public static PATIENT_ORDER_NEW = 'new'; // order-new
  public static PATIENT_ASSESSMENT_PLAN = 'assessment';
  public static PATIENT_WORKABILITY = 'workability';
  public static PATIENT_REFERRAL = 'referral';
  public static PATIENT_REPORTS_LIST = 'report';
  public static PATIENT_REPORT_VIEW = 'view'; // report-view
  public static PATIENT_REPORT_EDIT = 'edit'; // report-edit
  public static PATIENT_DOCS = 'docs';
  public static PATIENT_DOCS_UPLOAD = 'upload'; // docs-upload
  public static PATIENT_SHARE = 'share';
  public static PATIENT_SHARE_NEW = 'new'; // share-new
  public static PATIENT_SOAP = 'soap';
  public static PATIENT_SUPERBILL = 'superbill';
  public static PATIENT_PROOF_OF_SERVICE = 'pos';

  // under path /fin-accounts
  public static FIN_ACCOUNTS_BILLING = 'billing'; // billing
  public static FIN_ACCOUNTS_CLAIMS = 'claims'; // claims
  public static FIN_ACCOUNTS_ACCREC = 'accrec'; // accounts receivables

  // under path /case-flow
  public static CASE_FLOW = 'case-flow';
}
/*

switch (path) {
      case 'forms':
      case 'account':
        return ['fda', 'pmd', 'nrs'];

      case 'new':
      case 'docs':
      case 'upload':
      case 'docs/upload':
      case 'share':
      case 'share/new':
      case 'order':
      case 'assessment':
        return ['fda', 'pmd'];

      case 'pe':
      case 'dx':
      case 'practice':
      case 'order/new':
      case 'report':
        return ['pmd'];

      case 'history':
        return ['hst', 'nrs', 'pmd'];

      case 'app-new':
      case 'appointments':
      case 'appointments/app-new':
        return ['fda', 'hst', 'pmd'];

      default:
        return [];

input feature_path -> output role array

 */
