import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { SMSService } from '../../../pages/send-sms/sms.service';
import { PerfMonitorService } from '../perf-monitor.service';
import { AppState } from './../../../app.service';
import { AppVars } from './../../../app.vars';
import { GlobalState } from './../../../global.state';
import { WorkCompInsurance } from './accident';
import { Note, Patient, PatientDataEvents, PatientStatus } from './patient';
import { PatientLoggerService } from './patient-note.service';

interface MailMessage {
  from: string;
  to: string;
  cc?: string;
  bcc?: string;
  subject: string;
  content: string;
  replyTo?: string;
}

interface Payload {
  message: MailMessage;
  orgCode?: string;
}

interface PayloadForMultipleDocs {
  orgCode: string;
  clientId?: string;
  message: MailMessage;
  docs: any[];
}

// Named Cloud function
const SEND_EMAIL = 'sendEmail';
const SEND_EMAIL_WITH_ATTACHMENTS = 'sendMultipleDocsByEmail';

@Injectable()
export class PatientDataService {
  /* TODO: Update the following methods:
     4. Decide when to expire the access to 'Unique Patient Code'
     5. Verify using patient name, gender, dob - whether the patient account already exists in the system
  */

  allPatients: Observable<any[]>;
  // cash active patients obs
  activePatients$!: Observable<any[]>;
  callbackPatients$!: Observable<any[]>;
  orgData: any;
  constructor(
    private db: AngularFireDatabase,
    private afs: AngularFirestore,
    private afn: AngularFireFunctions,
    private _as: AppState,
    private _gs: GlobalState,
    private smsService: SMSService,
    private pns: PatientLoggerService,
    private perf: PerfMonitorService
  ) {
    // FIXME: Cannot get orgCode in the constructor - leaves the PatientDataService constructed in the memory, even post logout
    // this.orgCode = _as.get(AppVars.ORG_CODE);
    // this.patientsPath = `provider-org-${this.orgCode}/patients`; // => /provider-org-<orgCode>/patients

    const orgCode = this._as.get(AppVars.ORG_CODE);
    // const uSub = this.auth.user.subscribe((user) => {
    //   const orgCode = user.orgCode;
    const orgDataPath = `provider-org-${orgCode}/0rg-metadata`;
    console.log('[PatientDataService] constructed with orgCode =', orgCode);
    const tsub = this.db
      .object(orgDataPath)
      .valueChanges()
      .subscribe((d: any) => {
        // console.log('[orgData]:', d);
        const org = d;
        const orgName = org?.name;
        const orgPhone = org?.contactPhone;
        const address = [org?.address, org?.city, org?.state, org?.zip].join(' ');
        const streetAddress = org?.address || '';
        const city = org?.city || '';
        const state = org?.state || '';
        const zip = org?.zip || '';
        this.orgData = { orgCode, address, orgPhone, orgName, streetAddress, city, state, zip };
        console.log('OrgData:', this.orgData);
        tsub.unsubscribe();
      });

    // const pListPerfTraceOptions = {
    //   attributes: { orgCode: this.orgCode },
    // };
    // this.patientsList = this.getPatients().pipe(
    //   // Performance Tracing Patient List
    //   this.perf.setTrace('patientsList', pListPerfTraceOptions),
    //   shareReplay()
    // );
    // this.callbackPatientsList = this.getCallbackPatients().pipe(shareReplay());
    // this.activePatientsList = this.getActivePatients().pipe(
    //   // Performance Tracing Active Patient List
    //   this.perf.setTrace('activePatientsList', pListPerfTraceOptions),
    //   shareReplay()
    // );
    // // -------------
    // this.filterSubject = new Subject();
    this.allPatients = this.getAllPatients();
    //   uSub.unsubscribe();
    // });
  }

  /**
   * Patients List
   * To filter use filterPatientsByName(name) - this would filter the returned Observable
   * @return Observable<any[]>
   */
  public getAllPatients(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    // TODO: When to get all patients - refactor & limit to last 100
    // orderByChild('staffCallback').endAt('')
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('accountNumber').limitToLast(500))
      .snapshotChanges()
      .pipe(
        map((patients: any[]) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() })))
        // TODO: Performance Tracing Patient List
        // this.perf.setTrace('patientsList', { attributes: { orgCode } }),
        // shareReplay(1)
      );
  }

  /**
   * Get the List of Patients where the treatingDoctor is the current user
   * @returns Observable<any[]>
   */
  public getMyPatients(alias = null): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const user = this._as.get(AppVars.USER_ACCOUNT);
    console.log('[PatientDataService] getMyPatients() for user:', user, alias);
    if (!user.roles.pmd) return of([]);
    const patientsPath = `provider-org-${orgCode}/patients`;
    // Get the patients where treatingDoctor is either the user.displayName or user.alias and limit to last 100
    // Display Name matching entries
    const myPatients1$ = this.db
      .list(patientsPath, (ref) => ref.orderByChild('treatingDoctor').equalTo(user.displayName).limitToLast(100))
      .snapshotChanges()
      .pipe(map((patients: any[]) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() }))));

    if (alias) {
      // Alias matching entries
      const myPatients2$ = this.db
        .list(patientsPath, (ref) => ref.orderByChild('treatingDoctor').equalTo(alias).limitToLast(100))
        .snapshotChanges()
        .pipe(map((patients: any[]) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() }))));

      return merge(myPatients1$, myPatients2$);
    }
    return myPatients1$;
  }

  public getPatients(): Observable<any[]> {
    // return this.allPatients;
    return this.getAllPatients();
  }

  /**
   * Active Patients List
   * status is 'active'
   * @return Observable<any>
   */
  public getActivePatients(): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    if (!this.activePatients$ || this.orgData.orgCode !== orgCode) {
      console.log('[PatientDataService] getting Active Patients from RTDB');
      this.orgData = { ...this.orgData, orgCode };
      this.activePatients$ = this.getPatientsList(PatientStatus.ACTIVE);
    }
    console.log('[PatientDataService] replaying Active Patients from shareReplay()');
    return this.activePatients$;
  }

  getPatientRoROrders(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('rorOrder/orderedOn').limitToLast(50))
      .snapshotChanges()
      .pipe(map((patients) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() }))));
  }

  public getAllActivePatients(): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('status').equalTo(PatientStatus.ACTIVE))
      .snapshotChanges()
      .pipe(
        map((patients) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() }))),
        shareReplay(1)
      );
  }

  /**
   * Active Patients List
   * status is 'new'
   * @return FirebaseListObservable<any>
   */
  public getNewPatients(): Observable<any> {
    return this.getPatientsList(PatientStatus.NEW);
  }

  /**
   * Discharged Patients List
   * status is 'inactive'
   * @return FirebaseListObservable<any>
   */
  public getDischargedPatients(): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('status').equalTo(PatientStatus.INACTIVE).limitToLast(10))
      .snapshotChanges()
      .pipe(
        map((patients) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() }))),
        // TODO: Performance Tracing Inactive Patient List
        // this.perf.setTrace(`Inactive-PatientsList`, { attributes: { orgCode } }),
        shareReplay(1)
      );
  }

  public getHistoryOrderedPatients(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const patientsPath = `provider-org-${orgCode}/patients`;
    // TODO: Limit the results based on the scope of History Orders to pull from RTDB - Refer PRN-355
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('historyOrder/historyOrderedOn').limitToLast(50))
      .snapshotChanges()
      .pipe(
        map((patients) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() }))),
        map((patients) => (user.roles.pmd ? patients.filter((patient) => patient.treatingDoctor === user.displayName) : patients)),
        shareReplay(1)
      );
  }

  // For the given firstName, lastName and dob find the matching patient accounts
  public findPatientByNameDoB(firstName: string, lastName: string, dob: string): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;

    let start = firstName.toUpperCase().trim();
    let end = start + '\uf8ff';
    const patientsByFirstName$ = this.db
      .list(patientsPath, (ref) => ref.orderByChild('firstName').equalTo(start))
      .snapshotChanges()
      .pipe(map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() }))));

    start = lastName.toUpperCase().trim();
    end = start + '\uf8ff';
    const patientsByLastName$ = this.db
      .list(patientsPath, (ref) => ref.orderByChild('lastName').equalTo(start))
      .snapshotChanges()
      .pipe(
        map((patients: any[]) => patients.filter((patient) => patient.firstName == firstName.toUpperCase().trim())),
        map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() })))
      );

    let patientsByDoB$ = of([]);
    if (dob) {
      start = new Date(dob).toISOString();
      patientsByDoB$ = this.db
        .list(patientsPath, (ref) => ref.orderByChild('dob').equalTo(start))
        .snapshotChanges()
        .pipe(map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() }))));
    }
    const comibinedResults = combineLatest([patientsByFirstName$, patientsByLastName$, patientsByDoB$]);
    return comibinedResults.pipe(
      map(([a, b, c]) => {
        // TODO: Filter for matching DOB if dob is defined
        const allCases = [...a, ...b, ...c];
        // return from allCases array only unique objects where the accountNumber is unique - removing the duplicates
        return Array.from(new Set(allCases.map((a) => a.id))).map((id) => allCases.find((a) => a.id === id));
      })
    );
  }

  public searchPatiensByDob(dob: string): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const patientsPath = `provider-org-${orgCode}/patients`;
    // use start and end dates to search for
    const startDate = new Date(dob);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(dob);
    endDate.setHours(23, 59, 59, 0);
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('dob').startAt(startDate.toISOString()).endAt(endDate.toISOString()))
      .snapshotChanges()
      .pipe(
        map((patients) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() }))),
        map((patients) => (user.roles.pmd ? patients.filter((patient) => patient.treatingDoctor === user.displayName) : patients)),
        shareReplay(1)
      );
  }

  public searchPatients(searchFor: string, dob: string = '', status: PatientStatus = PatientStatus.ACTIVE): Observable<any[]> {
    const sterm = searchFor.trim();
    const start = sterm.toUpperCase();
    const end = start + '\uf8ff';
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const patientsPath = `provider-org-${orgCode}/patients`;
    const patientsByAccountNumber$ = this.db
      .list(patientsPath, (ref) => ref.orderByChild('accountNumber').startAt(start).endAt(end))
      .snapshotChanges()
      .pipe(
        map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() }))),
        map((patients) => patients.filter((p) => p.status === status)),
        map((patients) => patients.filter((p) => (dob ? p.dob.includes(dob) : true)))
      );
    const myPatientsByAccountNumber$ = patientsByAccountNumber$.pipe(
      map((patients) => patients.filter((p) => p.treatingDoctor === user.displayName))
    );
    const patientsByFirstName$ = this.db
      .list(patientsPath, (ref) => ref.orderByChild('firstName').startAt(start).endAt(end))
      .snapshotChanges()
      .pipe(
        map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() }))),
        map((patients) => patients.filter((p) => p.status === status)),
        map((patients) => patients.filter((p) => (dob ? p.dob.includes(dob) : true)))
      );

    const patientsByLastName$ = this.db
      .list(patientsPath, (ref) => ref.orderByChild('lastName').startAt(start).endAt(end))
      .snapshotChanges()
      .pipe(
        map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() }))),
        map((patients) => patients.filter((p) => p.status === status)),
        map((patients) => patients.filter((p) => (dob ? p.dob.includes(dob) : true)))
      );

    const myPatientsByFirstName$ = patientsByFirstName$.pipe(
      map((patients) => patients.filter((p) => p.treatingDoctor === user.displayName))
    );
    const myPatientsByLastName$ = patientsByLastName$.pipe(
      map((patients) => patients.filter((p) => p.treatingDoctor === user.displayName))
    );

    // combine the two observables - check if the current user is a PMD
    const comibinedResults = user.roles.pmd
      ? combineLatest([myPatientsByFirstName$, myPatientsByLastName$, myPatientsByAccountNumber$])
      : combineLatest([patientsByFirstName$, patientsByLastName$, patientsByAccountNumber$]);

    return comibinedResults.pipe(map(([a, b, c]) => [...a, ...b, ...c]));
  }

  /**
   * Patients List with Callback due for Staff Follow-up
   */
  getCallbackPatients(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    if (!this.callbackPatients$ || this.orgData.orgCode !== orgCode) {
      console.log('[PatientDataService] getting callback Patients from RTDB');
      this.orgData = { ...this.orgData, orgCode };
      this.callbackPatients$ = this.db
        .list(patientsPath, (ref) => ref.orderByChild('staffCallback').startAt('').limitToLast(50))
        .snapshotChanges()
        .pipe(
          map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() }))),
          shareReplay(1)
        );
    }
    console.log('[PatientDataService] replaying callback Patients from shareReplay()');
    return this.callbackPatients$;

    // return this.db
    //   .list(patientsPath, (ref) => ref.orderByChild('staffCallback').startAt(''))
    //   .snapshotChanges()
    //   .pipe(
    //     map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() }))),
    //     shareReplay(1)
    //   );
  }

  /**
   * Add a new Patient
   * Register a new patient account in the Provider Org
   * @param patient - valid Patient object with name, email address & dob of the patient
   * @return Promise with a unique patient code <string>
   */
  public async addNewPatient(patient: Patient, sendSMS = true): Promise<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    // return new Promise(async (resolve, reject) => {
    // Check whether the Patient object is compatible
    if (!patient) {
      // TODO: Cleanup: making patient dob optional when created using Quick Patient Create (copy/paste)
      // TODO: Cleanup: making patient email optional || !patient.email) {
      return Promise.reject('Invalid or missing patient details');
    }

    // What if the patient's cellPhone is not available? or invalid?
    if (patient.cellPhone && !this.isValidPhoneNumber(patient.cellPhone)) {
      return Promise.reject('Invalid Cell Phone number!');
    }

    /*
    // TODO: Stop if patient already exists (check for the phone number).
    const phoneExists = await this.isPhoneRegistered(patient.cellPhone);
    if (phoneExists) {
      return Promise.reject('Patient account already exists or another patient account has same Cell Phone Number.');
    }
    */

    console.log('Add New Patient: ', patient);
    // TODO: Why cleaning the object?
    const newPatient: any = this.clean({ ...patient });
    newPatient.dob = patient.dob ? new Date(patient.dob).toISOString() : null;
    newPatient.doi = patient.doi ? new Date(patient.doi).toISOString() : null;
    // ensure patient's first & last names are always in uppercase
    newPatient.firstName = patient.firstName.trim().toUpperCase();
    newPatient.lastName = patient.lastName.trim().toUpperCase();
    // newPatient.name = patient.name ? patient.name : patient.firstName + ' ' + patient.lastName;
    newPatient.name = `${newPatient.firstName} ${newPatient.lastName}`;
    // ensure the new patient status is set to ACTIVE
    newPatient.status = PatientStatus.ACTIVE;
    newPatient.orgCode = orgCode;

    /*
    // Add a callback, if there is an interpreter set - TODO: Move this to functions
    newPatient.callbackReason = patient.interpreter ? 'INTERPRETER' : null;
    newPatient.staffCallback = patient.interpreter ? new Date().toISOString() : null;
    */

    // Add new Patient Intake date as createdOn
    newPatient.createdOn = new Date().toISOString();
    console.log('Add New Patient before pushing: ', newPatient);

    const newNode = await this.db.list(patientsPath).push(newPatient);

    console.log('Pushed a new patient record: ', newPatient.email);
    console.log('Add New Patient: Patient Key = ', newNode.key);
    this._as.set(AppVars.SELECTED_PATIENT_KEY, newNode.key);
    this._gs.notifyDataChanged(AppVars.EVENT.PATIENT_SELECTED, newNode);
    // Add new patient intake audit log
    this.pns.patientLogger$.next({ patientKey: newNode.key, event: PatientDataEvents.NEW_INTAKE });

    /*
    // Add a new Patient Invitation so that the client can use Mobile App
    this.addPatientInvitation(newPatient.name, newPatient.cellPhone, sendSMS);
    */

    return Promise.resolve(newNode.key);

    // Now observe and obtain the Patient Code by querying the node
    // The server-function should have inserted the code after successful push
    // TODO: FIXME: Initially the patientCode value would be null (as the server functions code has to push the new 'code')
    // hence, it refires once the 'code' is updated - This could delay response in sync, if server function delays updating 'code'
    // Move subscription to the CODE into component class, just offer a subscription via Patient Object Observable
    // Once, server updates the code, the component with its subscription will handle the resolution of CODE value
    // TODO: Is there still a need to use Patient CODE - does the component depend on this?
    // const newPatientCodePath = `${patientsPath}/${newNode.key}/code`;
    // const tempSubscription = this.db
    //   .object(newPatientCodePath)
    //   .valueChanges()
    //   .subscribe(
    //     (patientCode) => {
    //       console.log('Server generated new Patient Code: ', patientCode);
    //       if (patientCode) {
    //         tempSubscription.unsubscribe();
    //         resolve(patientCode.toString());
    //       }
    //     },
    //     (err) => {
    //       return reject('Error while getting new Patient Code: ' + err.message);
    //     }
    //   );
    // });
    // });
  }

  /**
   * Get Patient Data
   * @param id - id or the key of the patient data node
   */
  public getPatient(patientKey: string): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    return this.db
      .object(`${patientsPath}/${patientKey}`)
      .snapshotChanges()
      .pipe(map((patient: any) => ({ id: patient.key, ...patient.payload.val() })));
  }

  /**
   * Get Patient Notes Data
   * @param id - id or the key of the patient data node
   */
  public getPatientNotes(patientKey: string): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientNotesPath = `provider-org-${orgCode}/patients/${patientKey}/notes`;
    return this.db
      .list(patientNotesPath)
      .snapshotChanges()
      .pipe(
        map((notes) => notes.map((note: any) => ({ ...note.payload.val(), id: note.key }))),
        shareReplay(1)
      );
  }

  /**
   * Get Patient History Notes Data
   * @param id - id or the key of the patient data node
   */
  public getPatientHxNotes(patientKey: string): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientHxNotesPath = `provider-org-${orgCode}/patients/${patientKey}/history-notes`;
    return this.db
      .list(patientHxNotesPath)
      .snapshotChanges()
      .pipe(
        map((notes) => notes.map((note: any) => ({ ...note.payload.val(), id: note.key }))),
        shareReplay(1)
      );
  }

  /**
   * Get Patient Audit Log Data
   * @param id - id or the key of the patient data node
   */
  public getPatientAuditLogs(patientKey: string): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientLogsPath = `provider-org-${orgCode}/patients/${patientKey}/audit-logs`;
    return this.db
      .list(patientLogsPath)
      .snapshotChanges()
      .pipe(
        map((logs) => logs.map((log: any) => ({ id: log.key, ...log.payload.val() }))),
        shareReplay(1)
      );
  }

  public getPatientNoteTypes(): Observable<unknown | string[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const noteTypesPath = `provider-org-${orgCode}/note-types`;
    return this.db.list(noteTypesPath).valueChanges();
  }

  /**
   * add a Patient Note
   * @param id - id or the key of the patient data node
   * @param note - Note object
   */
  public addPatientNotes(patientKey: string, note: Note) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    if (!note.author) {
      const user = this._as.get(AppVars.USER_ACCOUNT);
      note.author = user.displayName || user.email;
    }
    const patientNotesPath = `provider-org-${orgCode}/patients/${patientKey}/notes`;
    return this.db.list(patientNotesPath).push(note);
  }

  /**
   * add a Patient Note
   * @param id - id or the key of the patient data node
   * @param note - note object
   */
  public addPatientHxNotes(patientKey: string, note: any) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const user = this._as.get(AppVars.USER_ACCOUNT);
    note.author = user.displayName || user.email;
    note.date = new Date().toISOString();
    const patientNotesPath = `provider-org-${orgCode}/patients/${patientKey}/history-notes`;
    return this.db.list(patientNotesPath).push(note);
  }

  /**
   * update Patient History Note
   * @param id - id or the key of the patient data node
   * @param note - note object (author, date, description, type?)
   */
  public updatePatientHxNote(patientKey: string, note: any) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const user = this._as.get(AppVars.USER_ACCOUNT);
    note.author = user.displayName || user.email;
    note.date = new Date().toISOString();
    const patientNotePath = `provider-org-${orgCode}/patients/${patientKey}/history-notes/${note.id}`;
    return this.db.object(patientNotePath).update(note);
  }

  /**
   * delete Patient Note
   * @param patientId - id or the key of the patient data node
   * @param noteId - id of the note object
   */
  public deletePatientHxNote(patientKey: string, noteId: string) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientNotePath = `provider-org-${orgCode}/patients/${patientKey}/history-notes/${noteId}`;
    return this.db.object(patientNotePath).remove();
  }

  /**
   * update Patient Note
   * @param id - id or the key of the patient data node
   * @param note - note object (author, date, description, type?)
   */
  public updatePatientNote(patientKey: string, note: any) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientNotePath = `provider-org-${orgCode}/patients/${patientKey}/notes/${note.id}`;
    return this.db.object(patientNotePath).update(note);
  }

  /**
   * delete Patient Note
   * @param patientId - id or the key of the patient data node
   * @param noteId - id of the note object
   */
  public deletePatientNote(patientKey: string, noteId: string) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientNotePath = `provider-org-${orgCode}/patients/${patientKey}/notes/${noteId}`;
    return this.db.object(patientNotePath).remove();
  }

  /**
   * Set Patient Status
   * @param patientKey - Database key of the patient account
   * @param status - valid status values of NEW, ACTIVE, INACTIVE etc..
   * @return - void
   */
  public setPatientStatus(patientKey: string, status: PatientStatus): void {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    this.db.object(`${patientsPath}/${patientKey}`).update({ status: status });
  }

  /**
   * Save Patient's profile picture or URL of the image stored in Firebase Storage as profile picture
   * @param patientKey Database key of the patient for whome the profile picture to be updated
   * @param photoUrl Firebase storage URL path which holds the photo/image file
   */
  public saveProfilePicture(patientKey: string, photoUrl: string): any {
    return this.updatePatientAccount(patientKey, 'photoUrl', photoUrl);
  }

  /**
   * Update Patient's Account property field
   * @param patientKey Database key of the patient for whom the account property to be updated
   * @param field property field
   * @param value value of the property field
   */
  public updatePatientAccount(patientKey: string, field: string, value: any): any {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    const patientNodePath = `${patientsPath}/${patientKey}`;
    const updateObject = {};
    updateObject[field] = value;
    // TODO: Remove this ugly hack and refactor the code appropriately
    if (field === 'name') {
      const nameValues = value.split(' ');
      updateObject['lastName'] = nameValues[nameValues.length - 1];
      updateObject['firstName'] = nameValues[0];
    }
    console.log('[PatientDataService] updatePatientAccount(): updateObject = ', updateObject);
    // Add patient data update log
    this.pns.patientLogger$.next({
      patientKey,
      event: PatientDataEvents.PATIENT_DATA_UPDATED,
    });
    return this.db.object(patientNodePath).update(updateObject);
  }

  public updatePatientObject(patientKey: string, updateObj: any): any {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    const patientNodePath = `${patientsPath}/${patientKey}`;
    const updateObject = { ...updateObj };
    // ensure the patient's first & last name are always stored in uppercase
    if (updateObj.firstName || updateObj.lastName) {
      updateObject.firstName = updateObj.firstName.trim().toUpperCase();
      updateObject.lastName = updateObj.lastName.trim().toUpperCase();
      updateObject.name = `${updateObject.firstName} ${updateObject.lastName}`;
    }
    console.log('[PatientDataService] updatePatientObject(): updateObject = ', updateObject);
    // Add patient data update log
    // TODO: Instead use individual data segment update and add relevant note - extend PatientDataEvents
    this.pns.patientLogger$.next({
      patientKey,
      event: PatientDataEvents.PATIENT_DATA_UPDATED,
    });
    return this.db.object(patientNodePath).update(updateObject);
  }

  /**
   * Order History for Patient's Account
   * @param patientKey Database key of the patient for whom the account property to be updated
   */
  public orderHistoryForPatient(
    patientKey: string,
    historianId: string,
    historianName: string,
    historianEmail: string,
    dueDate: string,
    notes?: string,
    reorder = false
  ): any {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const userAccount = this._as.get(AppVars.USER_ACCOUNT);
    const orderedByEmail = userAccount.email;
    const orderedByName = userAccount.displayName || '';
    const patientsPath = `provider-org-${orgCode}/patients`;
    const patientNodePath = `${patientsPath}/${patientKey}/historyOrder`;
    const updateObject = {
      historyOrderedOn: new Date().toISOString(),
      // add historianId
      historianId,
      historianName: historianName,
      historianEmail: historianEmail,
      dueDate,
      orderedByName,
      orderedByEmail,
      status: 'PENDING',
    };
    // If the history is re-ordered, then remove the completedOn date
    if (reorder) {
      updateObject['historyCompletedOn'] = null;
    }
    // Add notes to the patient's history-notes
    if (notes) {
      const note = {
        date: new Date().toISOString(),
        author: this._as.get(AppVars.USER_ACCOUNT).displayName,
        description: notes,
      };
      this.addHistoryNotes(patientKey, note);
    }
    console.log('[PatientDataService] orderHistoryForPatient(): updateObject = ', updateObject);
    this.pns.patientLogger$.next({ patientKey, event: PatientDataEvents.HISTORY_ORDERED });
    // Updating Historian node with the patient & org ref is done in serverless-functions
    return this.db.object(patientNodePath).update(updateObject);
  }

  public updateHistoryOrderForPatient(patientKey: string, notes?: string, status?: string) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    const patientNodePath = `${patientsPath}/${patientKey}/historyOrder`;
    const updateObject = {
      status: status || 'PENDING',
      _lastUpdate: new Date().toISOString(),
    };
    // If the history is CANCELLED, then set missing notes as History Order Cancelled
    if (status === 'CANCELLED' && !notes) {
      notes = 'History Order Cancelled';
    }
    // Add the notes to the patient's history-notes
    if (notes) {
      const note = {
        date: new Date().toISOString(),
        author: this._as.get(AppVars.USER_ACCOUNT).displayName,
        description: notes,
      };
      this.addHistoryNotes(patientKey, note);
    }
    console.log('[PatientDataService] updateHistoryOrderForPatient(): updateObject = ', updateObject);
    return this.db.object(patientNodePath).update(updateObject);
  }

  /**
   * Get Patient's History Notes Data
   * @param id - id or the key of the patient data node
   */
  public getHistoryNotes(patientKey: string): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const historyNotesPath = `provider-org-${orgCode}/patients/${patientKey}/history-notes`;
    return this.db
      .list(historyNotesPath)
      .snapshotChanges()
      .pipe(
        map((notes) => notes.map((note: any) => ({ id: note.key, ...note.payload.val() }))),
        shareReplay(1)
      );
  }

  /**
   * add a Patient's History Note
   * @param id - id or the key of the patient data node
   * @param note - note object
   */
  public addHistoryNotes(patientKey: string, note: any) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const HistoryNotesPath = `provider-org-${orgCode}/patients/${patientKey}/history-notes`;
    return this.db.list(HistoryNotesPath).push(note);
  }

  /**
   * update Patient's History Note
   * @param id - id or the key of the patient data node
   * @param note - note object
   */
  public updateHistoryNote(patientKey: string, note: any) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const historyNotePath = `provider-org-${orgCode}/patients/${patientKey}/history-notes/${note.id}`;
    return this.db.object(historyNotePath).update(note);
  }

  /**
   * delete Patient's History Note
   * @param patientId - id or the key of the patient data node
   * @param noteId - id of the note object
   */
  public deleteHistoryNote(patientKey: string, noteId: string) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const historyNotePath = `provider-org-${orgCode}/patients/${patientKey}/history-notes/${noteId}`;
    return this.db.object(historyNotePath).remove();
  }

  public getHistorians(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const historiansPath = `/provider-org-${orgCode}/historians`;
    return this.db
      .list(historiansPath)
      .snapshotChanges()
      .pipe(map((historians: any[]) => historians.map((historian) => ({ id: historian.key, ...historian.payload.val() }))));
  }

  // TODO: Is list of RoR Providers required? How is the API Integration done?
  public getRorProviders(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const path = `/provider-org-${orgCode}/ror-providers`;
    return this.db
      .list(path, (ref) => ref.orderByChild('status').equalTo('Active'))
      .snapshotChanges()
      .pipe(map((rorProviders: any[]) => rorProviders.map((rorProvider) => ({ id: rorProvider.key, ...rorProvider.payload.val() }))));
  }

  public getBillingProviders(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const path = `/provider-org-${orgCode}/billers`;
    return this.db
      .list(path)
      .snapshotChanges()
      .pipe(
        map((billingProviders: any[]) =>
          billingProviders.map((billingProvider) => ({ id: billingProvider.key, ...billingProvider.payload.val() }))
        )
      );
  }

  /**
   * Order Record Review for Patient's Account
   * @param patientKey Database key of the patient for whom the account property to be updated
   */
  public orderRecordReviewForPatient(patientKey: string, ror: any): any {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const patient = this._as.get(AppVars.SELECTED_PATIENT);
    const patientsPath = `provider-org-${orgCode}/patients`;
    const patientNodePath = `${patientsPath}/${patientKey}/rorOrder`;
    // Notify the RoR Service Provider via email and update the record with status as 'EMAIL SENT'
    const message: MailMessage = this.getRoRNotificationMsg(patient, ror);
    const docs: any[] = ror.docs;
    // Send email notification for RoR only if the Notification Config has not blocked the email
    const orgData = this._as.get(AppVars.ORG_METADATA);
    const notificationConfig = orgData['notification-config'];
    if (notificationConfig.sendMail && notificationConfig?.blockRoRNotification !== true) {
      this.sendMailNotificationWithAttachments(message, docs, patientKey).subscribe((res: any) =>
        console.log('[PatientDataService] orderRecordReviewForPatient(): Email sent!', res)
      );
    }

    const updateObject = {
      orderedOn: new Date().toISOString(),
      status: notificationConfig.sendMail && notificationConfig?.blockRoRNotification !== true ? 'EMAIL SENT' : 'EMAIL NOT SENT',
      ...ror,
      orderedBy: user.displayName || user.email,
    };
    this.pns.patientLogger$.next({ patientKey, event: PatientDataEvents.RECORD_REVIEW_ORDERED });
    console.log('[PatientDataService] orderRecordReviewForPatient(): updateObject = ', updateObject);
    return this.db.object(patientNodePath).update(updateObject);
  }

  /**
   * Order Billing for Patient's Account
   * @param patientKey Database key of the patient for whom the account property to be updated
   */
  public orderBillingForPatient(patientKey: string, billingOrder: any): any {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    const patientNodePath = `${patientsPath}/${patientKey}/billingOrder`;
    // TODO: Send email notification for billing order
    // Notify the Billing Service Provider via email and update the record with status as 'PENDING'
    // const message: MailMessage = this.getBillingNotificationMsg(billingOrder);
    // this.sendMailNotification(message).subscribe((res: any) =>
    //   console.log('[PatientDataService] orderBillingForPatient(): Email sent!', res)
    // );
    const updateObject = {
      orderedOn: new Date().toISOString(),
      orderedByName: this._as.get(AppVars.USER_ACCOUNT).displayName || '',
      status: 'PENDING',
      ...billingOrder,
    };
    this.pns.patientLogger$.next({ patientKey, event: PatientDataEvents.BILLING_ORDERED });
    console.log('[PatientDataService] orderRecordReviewForPatient(): updateObject = ', updateObject);
    return this.db.object(patientNodePath).update(updateObject);
  }

  // ------------ All private methods required within this class -------

  // Obtain the list of patients as an Observable for the given status
  private getPatientsList(status: PatientStatus): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('status').equalTo(status).limitToLast(25))
      .snapshotChanges()
      .pipe(
        map((patients) => patients.map((patient: any) => ({ id: patient.key, ...patient.payload.val() }))),
        // TODO: Performance Tracing Active Patient List
        // this.perf.setTrace(`${status}-PatientsList`, { attributes: { orgCode } }),
        shareReplay(1)
      );
    // Instead of using .valueChanges(), use .snapshotChanges() so that for the resultant array, include the
    // 'id' property as the document/node key for every object in the array/list
  }

  public getActivePatientsList(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('status').startAt(0).endAt(1))
      .snapshotChanges()
      .pipe(
        map((patients: any[]) => patients.map((patient) => ({ id: patient.key, ...patient.payload.val() }))),
        // TODO: Performance Tracing Active Patient List
        // this.perf.setTrace(`Active-PatientsList`, { attributes: { orgCode } }),
        shareReplay()
      );
    // Instead of using .valueChanges(), use .snapshotChanges() so that for the resultant array, include the
    // 'id' property as the document/node key for every object in the array/list
  }

  public getCaseTypeCategories(caseType: string = 'WC'): Observable<string[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const categoriesPath = `provider-org-${orgCode}/case-type-categories`;
    return this.db
      .list<{ type: string; name: string }>(categoriesPath, (ref) => ref.orderByChild('type').equalTo(caseType))
      .valueChanges()
      .pipe(
        map((categories: { type: string; name: string }[]) => categories.map((category) => category.name)),
        map((cats) => cats.sort())
      );
  }

  /**
   * Retrieves a list of work compensation insurance data from the Firestore database.
   *
   * @return {Observable<any[]>} An observable array of work compensation insurance data.
   */
  public getWCInsuranceList(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const lookupPath = `provider-orgs/provider-org-${orgCode}/lookup-data`;
    return this.afs.collection(lookupPath, (ref) => ref.where('type', '==', 'workCompInsurance')).valueChanges();
  }

  /**
   * Retrieves a list of health insurance data from the Firestore database.
   *
   * @return {Observable<any[]>} An observable array of health insurance data.
   */
  public getHealthInsuranceList(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const lookupPath = `provider-orgs/provider-org-${orgCode}/lookup-data`;
    return this.afs.collection(lookupPath, (ref) => ref.where('type', '==', 'healthInsurance')).valueChanges();
  }

  /**
   * Retrieves a list of attorneys data from the Firestore database.
   *
   * @return {Observable<any[]>} An observable array of attorneys data.
   */
  public getAttorneyList(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const lookupPath = `provider-orgs/provider-org-${orgCode}/lookup-data`;
    return this.afs.collection(lookupPath, (ref) => ref.where('type', '==', 'attorney')).valueChanges();
  }

  /**
   * Retrieves a list of claims examiners data from the Firestore database.
   *
   * @return {Observable<any[]>} An observable array of claims examiners data.
   */
  public getClaimsExaminersList(): Observable<any[]> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const lookupPath = `provider-orgs/provider-org-${orgCode}/lookup-data`;
    return this.afs.collection(lookupPath, (ref) => ref.where('type', '==', 'claimsExaminer')).valueChanges();
  }

  // Check if the phone number is valid
  isValidPhoneNumber(phoneNumber: string): boolean {
    // Should contain only 10 digits
    const regex = /^\d{10,14}$/;
    // console.log('[PatientDataService] Phone No. Test:', phoneNumber, regex.test(phoneNumber), regex1.test(phoneNumber));
    return regex.test(phoneNumber);
  }

  // Add new patient invitation to enable Client App
  private addPatientInvitation(name: string, phoneNumber: string, sendSMS = true) {
    // Get the org name, address etc to support Client App invite validations
    const path = '/patient-invitations';
    // TODO: Only US Phone numbers would get invites
    const patientPhone = phoneNumber.startsWith('+1') ? phoneNumber : `+1${phoneNumber}`;
    console.log('[addPatientInvitation()]:', name, patientPhone);

    const patientInvitation = { ...this.orgData, ...{ name, phoneNumber: patientPhone } };
    // TODO: Check if there is already an existing invitation, if so what happens?
    this.afs
      .collection(path)
      .add(patientInvitation)
      .then(() => {
        console.log('New Patient Invite added!', patientPhone, ' Send Text:', sendSMS);
        const appLink = {
          iOS: 'https://apps.apple.com/us/app/myaccidentmate/id1617603827',
          android: 'https://play.google.com/store/apps/details?id=com.accidentmate.client',
        };
        // After adding a new patient invite, send a SMS notification to the client with invitation to download the app
        const message = `${this.orgData.orgName} has invited you to download the AccidentMATE mobile app. For iPhone, download the app from ${appLink.iOS} and for Android Phones, download the app from ${appLink.android}`;
        if (sendSMS) {
          this.smsService
            .sendSMS(null, patientPhone, message)
            .then(() => console.log('Add new Patient Invite: Invitation SMS sent!'))
            .catch((error) => console.log('Add new Patient Invite: Invitation SMS failed!', error));
        }
      })
      .catch((err) => console.log('ERROR! Could add new patient invite to Firestore!', err));
  }

  sendSMSToPatient(patientPhone: string, message?: string): Promise<any> {
    const appLink = {
      iOS: 'https://apps.apple.com/us/app/myaccidentmate/id1617603827',
      android: 'https://play.google.com/store/apps/details?id=com.accidentmate.client',
      web: 'https://portal.accidentmate.com',
    };
    // After adding a new patient invite, send a SMS notification to the client with invitation to download the app
    // message = `${this.orgData.orgName} has invited you to download the AccidentMATE mobile app. For iPhone, download the app from ${appLink.iOS} and for Android Phones, download the app from ${appLink.android} or Simply visit: ${appLink.web}`;
    const patientKey = this._as.get(AppVars.SELECTED_PATIENT_KEY);
    if (patientKey) {
      return this.smsService.sendSMS(patientKey, patientPhone, message);
    } else {
      return Promise.resolve();
    }
  }

  // Verify whether the patient account already exists in the provider network system
  isPhoneRegistered(phoneNumber): Promise<boolean> {
    // remove all non-digits
    const checkCellPhone = phoneNumber.replace(/\D/g, '').trim();
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientsPath = `provider-org-${orgCode}/patients`;
    // Check whether a unique property (cellPhone) is already existing in the 'patients list'
    return this.db
      .list(patientsPath, (ref) => ref.orderByChild('cellPhone').equalTo(checkCellPhone))
      .valueChanges()
      .pipe(
        take(1),
        map((pList: any[]) => {
          return pList.length > 0;
        })
      )
      .toPromise();
  }

  // TODO: Make the STD insurance data configurable via JGS
  public getSIBTFInsurance(): WorkCompInsurance {
    const sibtfInsurance: WorkCompInsurance = {
      name: 'Subsequent Injury Benefit Trust Fund',
      phoneNo: '9169284601',
      mailingAddressStreet: '1750 Howe Avenue, Suite 370',
      mailingAddressCity: 'Sacramento',
      mailingAddressState: 'CA',
      mailingAddressZip: '95825',
    };
    return sibtfInsurance;
  }

  // Clean the object by removing empty, null, undefined properties
  private clean(obj): any {
    const cleanObj = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop]) {
        cleanObj[prop] = obj[prop];
      }
    });
    return cleanObj;
  }

  // TODO: Move message generation to a separate service
  private getRoRNotificationMsg(patient: any, rorOrder: any): MailMessage {
    // prepare email template based on ror order details
    const dueDate = rorOrder.dueDate ? new Date(rorOrder.dueDate).toLocaleDateString() : '';
    const orderedOn = rorOrder.orderedOn ? new Date(rorOrder.orderedOn).toLocaleDateString() : new Date().toLocaleDateString();
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const orgData = this._as.get(AppVars.ORG_METADATA);
    // const patient = this._as.get(AppVars.SELECTED_PATIENT);

    const htmlPage = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <title>Record Review Order Notification</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <style>
            body { font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif }
            .notification-heading { display: flex; flex-direction: row; align-items: center;}
            .notification-logo { margin: 12px; width: 48px; height: 48px;}
            .notification-text { margin: 24px 72px; }
            .notification-sub-heading { color: red; }
          </style>
        </head>
        <body> {{bodyPlaceholder}} </body>
      </html>
      `;
    const attachmentsList = rorOrder.docs.map((d) => `<li><a href="${d.downloadUrl || d.downloadURL}">${d.fileName}</a></li>`);
    const bodyContent = `
    <h3>${orgData.name} has ordered for Record Review.</h3>
    <h4>Order Details:</h4>
    <div>${rorOrder.stat ? '<strong>This is a STAT order!</strong>' : ''}</div>
    <ul>
      <li>Account No: ${patient.accountNumber || ''}</li>
      <li>Patient Name: ${patient.firstName} ${patient.lastName}</li>
      <li>Date of Birth: ${patient.dob ? new Date(patient.dob).toLocaleDateString() : ''}</li>
      <li>Doctor: ${rorOrder.treatingDoctor || ''}</li>
      <li>Review Ordered On: ${orderedOn}</li>
      <li>Due Date for Completion: ${dueDate}</li>
      <li>Ordered By: ${user.displayName || user.email}</li>
    </ul>
    <div><strong>Notes:</strong> ${rorOrder.notes || ''}</div>
    <br/>
    <h4>Attachments:</h4>
    <ol>${attachmentsList.join('')}</ol>
    `;
    const subject = `${orgData.name} has ordered for Record Review.`;
    const content = htmlPage.replace(/{{bodyPlaceholder}}/g, bodyContent);
    // TODO: Add CC if configured
    const cc = ''; // notificationConfig.copyTo || '';
    const to = rorOrder.rorProvider.email || cc;
    const from = `"${user.displayName}" <notifications@accidentmate.com>`;
    const replyTo = `"${user.displayName || user.email}" <${user.email}>`;
    const mailMessage = { content, to, cc, subject, from, replyTo };
    console.log('Mail message details: ', mailMessage);
    return mailMessage;
  }

  private getLocaleDate(date: string): string {
    const timezone = 'America/Los_Angeles';
    const dateValue = new Date(date);
    return dateValue.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: timezone,
    });
  }

  private sendMailNotification(message: MailMessage): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const payload: Payload = { message, orgCode };
    const sendEmail = this.afn.httpsCallable(SEND_EMAIL);
    return sendEmail(payload);
  }

  private sendMailNotificationWithAttachments(message: MailMessage, docs: any[], patientKey: string): Observable<any> {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const payloadForMultipleDocs: PayloadForMultipleDocs = { orgCode: orgCode, message: message, docs: docs, clientId: patientKey };
    const sendMultipleDocsByEmail = this.afn.httpsCallable(SEND_EMAIL_WITH_ATTACHMENTS);
    return sendMultipleDocsByEmail(payloadForMultipleDocs);
  }
}
