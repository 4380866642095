import { Injectable } from '@angular/core';
import { AppState } from '../../../app.service';
import { AppVars } from '../../../app.vars';

@Injectable({ providedIn: 'root' })
export class DataPathService {
  constructor(private _as: AppState) {}

  // Org Level Generic Data Paths
  getOrgRoot(): string {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    // TODO: Update to start from common /provider-orgs/provider-org-{orgCode} node
    return `provider-org-${orgCode}`;
  }

  getOrgDataPath(): string {
    return `${this.getOrgRoot()}/0rg-metadata`;
  }

  getPatientsPath(): string {
    return `${this.getOrgRoot()}/patients`;
  }

  getPatientOrdersPath(): string {
    return `${this.getOrgRoot()}/patient-orders`;
  }

  getPatientOfficeFormsPath(): string {
    return `${this.getOrgRoot()}/patient-office-forms`;
  }

  getPatientAppointmentsPath(): string {
    return `${this.getOrgRoot()}/patient-appointments`;
  }

  getPatientReferralsPath(): string {
    return `${this.getOrgRoot()}/patient-referrals`;
  }

  getDocTemplatesPath(): string {
    return `${this.getOrgRoot()}/doc-templates`;
  }

  getHistoryFormsPath(): string {
    return `${this.getOrgRoot()}/history-forms`;
  }

  getPhysicalExamsPath(): string {
    return `${this.getOrgRoot()}/physical-exams`;
  }

  getProvidersPath(): string {
    return `${this.getOrgRoot()}/providers`;
  }

  getHistoriansPath(): string {
    return `${this.getOrgRoot()}/historians`;
  }

  getFacilitiesPath(): string {
    return `${this.getOrgRoot()}/facilities`;
  }

  getOrderTypesPath(): string {
    return `${this.getOrgRoot()}/order-types`;
  }

  // Patient specific data paths - all methods need a Patient ID to resolve
  getPatientDataPath(patientId: string): string {
    return `${this.getOrgRoot()}/patients/${patientId}`;
  }

  getPatientEncountersPath(patientId: string): string {
    return `${this.getOrgRoot()}/patients/${patientId}/encounters`;
  }

  getPatientFilestorePath(patientId: string): string {
    return `${this.getOrgRoot()}/patients/${patientId}/filestore`;
  }

  getPatientFormsPath(patientId: string): string {
    return `${this.getOrgRoot()}/patients/${patientId}/forms`;
  }

  getPatientCaseFlowPath(patientId: string): string {
    return `${this.getOrgRoot()}/patients/${patientId}/caseFlow`;
  }

  getPatientCaseFlowAIPath(patientId: string, actionItemId: string): string {
    return `${this.getOrgRoot()}/patients/${patientId}/caseFlow/actionItems/${actionItemId}`;
  }

  getCaseFlowPath(id?: string): string {
    const specificId = id ? `/${id}` : '';
    return `${this.getOrgRoot()}/case-workflows${specificId}`;
  }
}
