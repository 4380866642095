import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';

import { AppState } from '../../../app.service';
import { AppVars } from '../../../app.vars';
import { AuditLog, patientDataEventDescriptions, PatientDataEvents } from './patient';

@Injectable({
  providedIn: 'root',
})
export class PatientLoggerService {
  /* - PRAPP-46 - Add pre-defined audit logs for the following events
      New Patient Intake: [1]
      Patient Data Update: [2, 3, 4, 5, 6]
      Appointment Data Update: [7, 8, 9, 10, 11, 12, 19]
      SOAP Data Update: [16, 18]
      Send Office Forms: [13]
      Superbill Update: [15]
  */
  patientLogger$: BehaviorSubject<{ patientKey: string; event: PatientDataEvents; extra?: string }> = new BehaviorSubject(null);

  constructor(private db: AngularFireDatabase, private _as: AppState) {
    this.patientLogger$.subscribe((data) => {
      if (data && data.patientKey) {
        const log = this.getLogData(data.event, data.extra || '');
        this.addLog(data.patientKey, log).catch((err) => console.log('ERROR while adding Patient Data Event Log:', err));
      }
    });
  }

  /**
   * add a Patient Audit Log
   * @param patientKey - id or the key of the patient data node
   * @param log - AuditLog object
   */
  private addLog(patientKey: string, log: AuditLog) {
    const orgCode = this._as.get(AppVars.ORG_CODE);
    const patientAuditLogsPath = `provider-org-${orgCode}/patients/${patientKey}/audit-logs`;
    return this.db.list(patientAuditLogsPath).push(log);
  }

  private getLogData(event: PatientDataEvents, extra: string = ''): AuditLog {
    const user = this._as.get(AppVars.USER_ACCOUNT);
    const author = user.displayName || user.email;
    const date = new Date().toISOString();
    return {
      date,
      author,
      description: patientDataEventDescriptions[event] + extra,
    };
  }
}
