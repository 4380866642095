import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { AuthService } from './auth.service';
import { Roles } from './auth.user';

@Injectable()
export class RoleAuthService {
  userRoles: Roles;
  constructor(private authService: AuthService) {
    // TODO: FIXME: When should this subscription be unsubscribed - test for consistency with Login/Logout??
    this.authService.user.subscribe((user) => {
      // console.log('[RoleAuthService] isAuthorized(): Got user from AuthService.user = ', user ? user.roles : null);
      this.userRoles = user ? user.roles : null;
    });
  }

  public isAuthorized(path: string, roles?: Roles): Observable<boolean> {
    if (!roles) {
      roles = this.userRoles ? this.userRoles : null;
    }
    const userRoles = roles ? Object.getOwnPropertyNames(roles).filter((role) => roles[role]) : [];
    const authorized = this.getAccess(this.getAuthorizedRoles(path), userRoles);
    // console.log('[RoleAuthService] isAuthorized(): path & roles = ', { path, userRoles, authorized });
    return observableOf(authorized);
  }

  private getAccess(authorizedRoles: string[], userRoles: string[]): boolean {
    const authorized = authorizedRoles.filter((role) => userRoles.includes(role)).length > 0;
    return authorized;
  }

  private getAuthorizedRoles(path: string): string[] {
    // TODO: Map the paths to sets of authorized roles - get this from firebase data
    // TODO: Remove hardcoded path - role maps
    // TODO: Reverse path vs role mapping & define explicitly
    // TODO: Design & include Registered Modules to guard the Paths
    // FIXME: secondary paths mapped together do not work ex: for 'fda' path 'order/new' is blocked, but it still allows
    switch (path) {
      case 'dashboard':
      case 'account':
      case 'docs':
      case 'scheduler':
      case 'patients':
      case 'upload':
      case 'docs/upload':
      case 'esign':
        return ['fda', 'pmd', 'tmp', 'adm'];

      case 'intake':
      case 'forms':
      case 'share':
      case 'share/new':
      case 'order':
      case 'assessment':
      case 'soap':
      case 'workability':
      case 'referral':
      case 'superbill':
      case 'pe':
      case 'dx':
      case 'practice':
      case 'order/new':
      case 'report':
      case 'pos':
      case 'notes':
        return ['fda', 'pmd'];
      case 'history':
      case 'ror':
        return ['fda'];

      case 'appointments':
        return ['fda', 'hst', 'pmd', 'tmp'];

      case 'app-new':
      case 'appointments/app-new':
        return ['fda', 'hst', 'pmd'];

      case 'new':
      case 'fin-accounts':
      case 'billing':
      case 'accrec':
      case 'claims':
      case 'case-flow':
      case 'caseFlow':
        return ['fda', 'adm'];

      default:
        return [];
    }
  }
}
